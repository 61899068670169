// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_sp d_fn d_bF d_dt";
export var alignLeft = "s_pR d_fn d_bF d_dt";
export var alignDiscCenter = "s_sq d_fp d_bC d_dv";
export var alignCenter = "s_bN d_fp d_bC d_dv";
export var alignDiscRight = "s_sr d_fq d_bG d_dw";
export var alignRight = "s_pS d_fq d_bG d_dw";
export var footerContainer = "s_ss d_dV d_bV";
export var footerContainerFull = "s_st d_dS d_bV";
export var footerHeader = "s_kd d_kd";
export var footerTextWrapper = "s_sv d_v";
export var footerDisclaimerWrapper = "s_kl d_kl d_cj";
export var badgeWrapper = "s_sw d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "s_sx undefined";
export var footerDisclaimerLeft = "s_sy undefined";
export var verticalTop = "s_sz d_by d_bH d_bM d_bK";
export var firstWide = "s_sB";
export var disclaimer = "s_sC d_by d_bH";
export var socialDisclaimer = "s_sD";
export var left = "s_sF";
export var wide = "s_sG d_cx";
export var right = "s_sH d_bJ";
export var line = "s_fj d_fk d_ct";
export var badgeDisclaimer = "s_sJ d_bB d_bN d_bH";
export var badgeContainer = "s_sK d_by d_bG d_bN";
export var badge = "s_sL";
export var padding = "s_sM d_c6";
export var end = "s_sN d_bG";
export var linkWrapper = "s_sP d_dz";
export var link = "s_mY d_dz";
export var colWrapper = "s_sQ d_cw";
export var consent = "s_f d_f d_bB d_bN";
export var media = "s_sR d_bw d_dx";
export var itemRight = "s_sS";
export var itemLeft = "s_sT";
export var itemCenter = "s_sV";
export var exceptionWeight = "s_sW q_qX";