// extracted by mini-css-extract-plugin
export var alignLeft = "m_pR d_fn d_bF d_dt";
export var alignCenter = "m_bN d_fp d_bC d_dv";
export var alignRight = "m_pS d_fq d_bG d_dw";
export var FAQMainHeader = "m_kt d_kt d_v d_bx";
export var FAQComponentHeader = "m_kz d_kz d_bw";
export var FAQComponentParagraph = "m_kv d_kv d_cC";
export var FAQComponentsWrapper = "m_kr d_kr d_cm d_cK d_cQ d_c1 d_cW d_dz";
export var FAQComponentsWrapperClean = "m_ks d_ks d_cm d_cK d_cQ d_cZ d_dz";
export var accordion__body = "m_kB d_kB d_bx";
export var accordion__bodyHidden = "m_kD d_kD d_bv d_dk";
export var FAQDivider = "m_kq d_kq";
export var accordionWrapper = "m_pT d_cf";
export var accordion__item = "m_pV d_cK";
export var accordion_arrow = "m_kH d_kH";
export var accordion_arrow_open = "m_kK d_kK";
export var accordion_arrow_2 = "m_kM d_kM";
export var accordion_arrow_2_open = "m_kP d_kP";
export var Subtitle2Small = "m_pW q_pW q_qv q_qL";
export var Subtitle2Normal = "m_pX q_pX q_qv q_qM";
export var Subtitle2Large = "m_pY q_pY q_qv q_qN";