// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sX d_gt d_cr";
export var heroHeaderCenter = "t_gv d_gv d_cr d_dv";
export var heroHeaderRight = "t_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "t_sY d_gq d_cv";
export var heroParagraphCenter = "t_gr d_gr d_cv d_dv";
export var heroParagraphRight = "t_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "t_sZ d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "t_s0 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "t_s1 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "t_s2 d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "t_s3 d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "t_rg q_rg";
export var heroExceptionNormal = "t_rh q_rh";
export var heroExceptionLarge = "t_rj q_rj";
export var Title1Small = "t_q0 q_q0 q_qv q_qw";
export var Title1Normal = "t_q1 q_q1 q_qv q_qx";
export var Title1Large = "t_q2 q_q2 q_qv q_qy";
export var BodySmall = "t_q6 q_q6 q_qv q_qP";
export var BodyNormal = "t_q7 q_q7 q_qv q_qQ";
export var BodyLarge = "t_q8 q_q8 q_qv q_qR";