// extracted by mini-css-extract-plugin
export var customText = "l_pK d_dt d_cr d_cf";
export var videoIframeStyle = "l_pj d_d4 d_v d_G d_bx d_b0 d_Q";
export var customImageWrapper = "l_pL d_cr d_cf d_Y";
export var customRow = "l_pD d_bC d_bd";
export var quoteWrapper = "l_gP d_bB d_bN d_cr d_cf";
export var quoteBar = "l_pn d_G";
export var masonryImageWrapper = "l_pv";
export var title = "l_pM";
export var Title3Small = "l_pN q_pN q_qv q_qD";
export var Title3Normal = "l_pP q_pP q_qv q_qF";
export var Title3Large = "l_pQ q_pQ q_qv q_qG";