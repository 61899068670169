// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "x_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "x_fQ d_fQ d_by d_bH";
export var menuDesign6 = "x_tm d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "x_tn d_fL d_by d_dv d_bN d_bH";
export var menuRight = "x_tp d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "x_tq d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "x_tr d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "x_p4 d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "x_qf d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "x_ts";
export var navbarItem = "x_p5 d_bw";
export var navbarLogoItemWrapper = "x_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "x_tt d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "x_tv d_gc d_bx d_Y d_br";
export var burgerToggle = "x_tw d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "x_tx d_gc d_bx d_Y d_br";
export var burgerInput = "x_ty d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "x_tz d_f2 d_v d_G";
export var burgerLine = "x_tB d_f0";
export var burgerMenuDesign6 = "x_tC d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "x_tD d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "x_tF d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "x_tG d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "x_tH d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "x_tJ d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "x_tK d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "x_tL d_bB d_bN";
export var staticBurger = "x_tM";
export var menu = "x_tN";
export var navbarDividedLogo = "x_tP";
export var nav = "x_tQ";